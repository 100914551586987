<template>
    <li class="nav-item dropup">
        <b-dropdown  variant="link"  dropup toggle-class="text-decoration-none" no-caret >
            <template #button-content >
                <a class="dropdown-toggle dropdown-indicator has-indicator nav-link" data-toggle="dropdown" data-offset="0,10"><small>English</small></a>
              </template>
              <div class="dropdown-menu-sm dropdown-menu-right" style="min-width: 130px;">
                <ul class="language-list">
                    <li v-for="lang in langs">
                        <a href="#" class="language-item">
                            <img :src="lang.img" alt="" class="language-flag">
                            <span class="language-name">{{ lang.lang }}</span>
                        </a>
                    </li>
                </ul>
            </div>
          </b-dropdown>
    </li>
</template>
<script>
    export default {
        name: 'langMenu',
        data() {
            return {
                langs: [
                    {
                        img:"@/assets/core/flags/english.png",
                        lang:'English',
                        sigla:'en'
                    },
                    {
                        img:"@/assets/core/flags/spanish.png",
                        lang:'Español',
                        sigla:'es'
                    },
                    {
                        img:"@/assets/core/flags/french.png",
                        lang:'Français',
                        sigla:'fr'
                    },
                    {
                        img:"@/assets/core/flags/turkey.png",
                        lang:'Türkçe',
                        sigla:'tr'
                    }
                ]
            }
        },
        methods: {
            showPassF() {
                this.showPass =!this.showPass;
            }
        }
    }
</script>