import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
//import router from './router/index'
import router from './router/indexActivate'
//import router from './router/indexBoard'
import store from './store'
import './devexperess'
import './gexter'
import './bootstrap'
import i18n from './i18n'

import VueGeolocation from 'vue-browser-geolocation'
Vue.use(VueGeolocation);

import SequentialEntrance from 'vue-sequential-entrance'
import 'vue-sequential-entrance/vue-sequential-entrance.css'
Vue.use(SequentialEntrance);

const Hashids = require('hashids/cjs')

Vue.prototype.$hashCript = new Hashids('Inoque Manxa Gexter Developer');


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
