const baseUrl = process.env.VUE_APP_BASE_URL;

const linguas = baseUrl+"/apoio/linguas";
const titulos = baseUrl+"/apoio/titulos";
const sys_cargo = baseUrl+"/apoio/syscargos";
const funcoesURL = baseUrl+"/funcao/select";
const departamentoURL = baseUrl+"/departamento/select";
const senderidURL = baseUrl+"/senderid/select";
const prasoContratualURL = baseUrl+"/prasocontratual/select";
const taxaivaURL = baseUrl+"/taxas/iva/select";
const taxaRetencaoURL = baseUrl+"/taxas/retencao/select";
const condicaoPagamentoURL = baseUrl+"/condicaopagamento/select";
const grupoClienteURL = baseUrl+"/grupos/select";
const contasBancarias01URL = baseUrl+"/contabancaria01/select";
const listSoftwareURL = baseUrl+"/software/select";
const tPRelacoesURL = baseUrl+"/tprelacao/listar";
const tipologias = baseUrl+"/tfracao/tipologias";
const subCategoriaURL = baseUrl+"/artigo/subcategorias";
const CategoriaURL = baseUrl+"/artigo/categorias";
const UnidadesMedidasURL = baseUrl+"/artigo/unidades";
const ArmazemURL = baseUrl+"/artigo/armazens";
const TipoValorizacaoURL = baseUrl+"/apoio/tipovalorizacao";
const CoorportaeEmailsUrl = baseUrl+"/corporate/email/select";
const CoorporteSyncEmailsUrl = baseUrl+"/corporate/email/sync";
const TipoDocUrl = baseUrl + "/gexter/tpdocs";

import store from 'storejs';
export default {
    state: {
        linguagens: [],
        corporate_emails: [],
        corporate_emails_sync: [],
        corporate_email_select: {},
        provincias: {},
        municipio: {},
        comunas: {},
        titulos: [],
        syscargos: [],
        softwares: [],
        taxas_iva: [],
        taxas_retencao: [],
        condicao_pagamento: [],
        contas_bancarias_01: [],
        grupo_clientes: [],
        select_clientes: [],
        departamentos: [],
        senderIds: [],
        tipoDocs: [],
        em_type_doc:1,
        prazocontratualA1: [],
        funcoes: [],
        moradas: [],
        form: [],
        zonas: [],
        tipologias: [],
        valorizacao: [],
        funcoes: [],
        categorias_artigo: [],
        unidades_medidas_artigo: [],
        armazem: [],
        tprelacoes: [],
        jwt: sessionStorage.jwt,
    },
    getters: {
        gtTipologias(state) {
            return state.tipologias;
        },
        gtZonas(state) {
            return state.zonas;
        }
    },
    mutations: {
        setPaises(state, data) {
            state.paises = data
        },
        setTitulos(state, data) {
            state.titulos = data
        },
        setSyscargos(state, data) {
            state.syscargos = data
        },
        setLinguas(state, data) {
            state.linguagens = data
        },
        setMoradas(state, data) {
            state.moradas = data
        },
        setZonas(state, data) {
            state.zonas = data
        },
        setTipologias(state, data) {
            state.tipologias = data
        },
        setFuncoes(state, data) {
            state.funcoes = data
        },
        setDepartamentos(state, data) {
            state.departamentos = data
        },
        setSenderID(state, data) {
            state.senderIds = data
        },
        setPrazoContratual(state, data) {
            state.prazocontratualA1 = data
        },
        setTpRelacoes(state, data) {
            state.tprelacoes = data
        },
        setTaxasIva(state, data) {
            state.taxas_iva = data
        },
        setTaxasretencao(state, data) {
            state.taxas_retencao = data
        },
        setCategoriaArtigos(state, data) {
            state.categorias_artigo = data
        },
        setUnidadesMedidas(state, data) {
            state.unidades_medidas_artigo = data
        },
        setArmazem(state, data) {
            state.armazem = data
        },
        setTDocs(state, data) {
            state.tipoDocs = data
        },
        setCondicaoPagamento(state, data) {
            state.condicao_pagamento = data
        },
        setContasBancarias01(state, data) {
            state.contas_bancarias_01 = data
        },
        setGrupoCliente(state, data) {
            state.grupo_clientes = data
        },
        setSoftware(state, data) {
            state.softwares = data
        },
        resetActiveDark(state) {
            state.darkActive = false;
        },
        setClientAtive(state,data) {
            state.select_clientes = data;
        },
        setForm(state,data) {
            state.form = data;
        },
        setDocEmicao(state,id_doc) {
            state.em_type_doc = id_doc;
        },
        setValorizacao(state,data) {
            state.valorizacao = data;
        },
        setCoorporateEmail(state,data) {
            state.corporate_emails = data;
        },
        setCoorporateEmailSync(state,data) {
            let selected = undefined;
            selected = data.find(element => element.status === 1);

            if(selected === undefined)
               selected = data[0];

            state.corporate_email_select = selected;
            state.corporate_emails_sync = data;
        },
        setEmailBoxSelect_(state,selected) {
            state.corporate_email_select = selected;
        }
    },
    actions: {
        async getCoorporateEmails(context) {
            if(store.has('corporate_emails')) {
                let data = store.get('corporate_emails');
                context.commit("setCoorporateEmail", data);
            } else {
                let data = (await context.getters.authenticatedAxios.get(CoorportaeEmailsUrl)).data
                store.set('corporate_emails',data)
                context.commit("setCoorporateEmail", data);
            }
        },
        async getCoorporateEmailsSync(context,vr_ = false) {
            if(store.has('corporate_emails_sync')) {
                let data = store.get('corporate_emails_sync');
                context.commit("setCoorporateEmailSync", data);
            }
            
            if(!store.has('corporate_emails_sync') || vr_){
                let data = (await context.getters.authenticatedAxios.get(CoorporteSyncEmailsUrl)).data
                store.set('corporate_emails_sync',data)
                context.commit("setCoorporateEmailSync", data);
            }
        },
        async getPaises(context) {
            if(store.has('paises')) {
                let data = store.get('paises');
                context.commit("setPaises", data);
            } else {
                let data = (await context.getters.authenticatedAxios.get(paises)).data
                store.set('paises',data)
                context.commit("setPaises", data);
            }

        },
        setFormA(context,data) {
            context.commit("setForm", data);
        },
        setEmicaoDoc(context,data) {
            context.commit("setDocEmicao", data);
        },
        async getTitulos(context) {
            if(store.has('titulos')) {
                let data = store.get('titulos');
                context.commit("setTitulos", data);
            } else {
                let data = (await context.getters.authenticatedAxios.get(titulos)).data
                store.set('titulos',data.data.titulos)
                context.commit("setTitulos", data.data.titulos);
            }
        },
        async getSysCargos(context) {
            if(store.has('syscargos')) {
                let data = store.get('syscargos');
                context.commit("setSyscargos", data);
            } else {
                let data = (await context.getters.authenticatedAxios.get(sys_cargo)).data
                store.set('syscargos',data.data.sys_cargo)
                context.commit("setSyscargos", data.data.sys_cargo);
            }
        },
        async getLinguas(context) {
            if(store.has('linguagens')) {
                let data = store.get('linguagens');
                context.commit("setLinguas", data);
            } else {
                let data = (await context.getters.authenticatedAxios.get(linguas)).data
                store.set('linguagens',data.data.linguas)
                context.commit("setLinguas", data.data.linguas);
            }
        },
        async getFuncoes(context) {
            if(store.has('funcoes')) {
                let data = store.get('funcoes');
                context.commit("setFuncoes", data);
            } else {
                let data = (await context.getters.authenticatedAxios.get(funcoesURL)).data
                store.set('funcoes',data)
                context.commit("setFuncoes", data);
            }
        },
        async getTipoValorizacao(context) {
            if(store.has('tipo_valorizacao')) {
                let data = store.get('tipo_valorizacao');
                context.commit("setValorizacao", data);
            } else {
                let data = (await context.getters.authenticatedAxios.get(TipoValorizacaoURL)).data
                store.set('tipo_valorizacao',data.data)
                context.commit("setValorizacao", data.data);
            }
        },
        async getDepartamentos(context) {
                let data = (await context.getters.authenticatedAxios.get(departamentoURL)).data
                context.commit("setDepartamentos", data.data.departamentos);
        },
        async getSenderID(context) {
                let data = (await context.getters.authenticatedAxios.get(senderidURL)).data
                context.commit("setSenderID", data.data.senderid);
        },
        async getPrazoContratual(context) {
                let data = (await context.getters.authenticatedAxios.get(prasoContratualURL)).data
                context.commit("setPrazoContratual", data.data.prazocontratual);
        },
        async getTpRelacoes(context) {
            if(store.has('Tprelacoes')) {
                let data = store.get('Tprelacoes');
                context.commit("setTpRelacoes", data);
            } else {
                let data = (await context.getters.authenticatedAxios.get(tPRelacoesURL)).data
                store.set('Tprelacoes',data)
                context.commit("setTpRelacoes", data);
            }
        },
        async getTaxasIvas(context) {
            if(store.has('taxas_iva')) {
                let data = store.get('taxas_iva');
                context.commit("setTaxasIva", data);
            } else {
                let data = (await context.getters.authenticatedAxios.get(taxaivaURL)).data
                store.set('taxas_iva',data.data.taxas)
                context.commit("setTaxasIva", data.data.taxas);
            }
        },
        async getTipoDocs(context,vr_ = false) {
            if(store.has('tipo_docs')) {
                let data = store.get('tipo_docs');
                context.commit("setTDocs", data);
            }

            if(!store.has('tipo_docs') || vr_) {
                let data = (await context.getters.authenticatedAxios.get(TipoDocUrl)).data
                store.set('tipo_docs',data.data.docs)
                context.commit("setTDocs", data.data.docs);
            }
        },
        async getTaxasRetencao(context,vr_ = false) {
            console.log(vr_);
            if(store.has('taxas_retencao')) {
                let data = store.get('taxas_retencao');
                context.commit("setTaxasretencao", data);
            } 
            
            if(!store.has('taxas_retencao') || vr_){
                let data = (await context.getters.authenticatedAxios.get(taxaRetencaoURL)).data
                store.set('taxas_retencao',data.data.taxas)
                context.commit("setTaxasretencao", data.data.taxas);
            }
        },
        async getSubCategorias(context,vr_ = false) {
            console.log(vr_);
            if(store.has('subcategoria_artigos')) {
                let data = store.get('subcategoria_artigos');
                context.commit("setTaxasretencao", data);
            } 
            
            if(!store.has('subcategoria_artigos') || vr_){
                let data = (await context.getters.authenticatedAxios.get(subCategoriaURL)).data
                store.set('subcategoria_artigos',data.data.subcategoria)
                context.commit("setTaxasretencao", data.data.subcategoria);
            }
        },
        async getCategoriasArtigos(context,vr_ = false) {
            console.log(vr_);
            if(store.has('categoria_artigos')) {
                let data = store.get('categoria_artigos');
                context.commit("setCategoriaArtigos", data);
            } 
            
            if(!store.has('categoria_artigos') || vr_){
                let data = (await context.getters.authenticatedAxios.get(CategoriaURL)).data
                store.set('categoria_artigos',data.data.categoria)
                context.commit("setCategoriaArtigos", data.data.categoria);
            }
        },
        async getArmazem(context,vr_ = false) {
            console.log(vr_);
            if(store.has('armazem_artigos')) {
                let data = store.get('armazem_artigos');
                context.commit("setArmazem", data);
            } 
            
            if(!store.has('armazem_artigos') || vr_){
                let data = (await context.getters.authenticatedAxios.get(ArmazemURL)).data
                store.set('armazem_artigos',data.data.armazem)
                context.commit("setArmazem", data.data.armazem);
            }
        },
        async getUnidadesMedidasArtigos(context,vr_ = false) {
            console.log(vr_);
            if(store.has('unidades_medidas_artigos')) {
                let data = store.get('unidades_medidas_artigos');
                context.commit("setUnidadesMedidas", data);
            } 
            
            if(!store.has('unidades_medidas_artigos') || vr_){
                let data = (await context.getters.authenticatedAxios.get(UnidadesMedidasURL)).data
                store.set('unidades_medidas_artigos',data.data.unidade)
                context.commit("setUnidadesMedidas", data.data.unidade);
            }
        },
        async getCondicoesPagamentos(context,vr_ = false) {
            if(store.has('condicao_pagamento')) {
                let data = store.get('condicao_pagamento');
                context.commit("setCondicaoPagamento", data);
            }

            if(!store.has('condicao_pagamento') || vr_) {
                let data = (await context.getters.authenticatedAxios.get(condicaoPagamentoURL)).data
                store.set('condicao_pagamento',data.data.condicao);
                context.commit("setCondicaoPagamento", data.data.condicao);
            }
        },
        async getGrupoClientes(context,vr_ = false) {
            if(store.has('grupo_cliente')) {
                let data = store.get('grupo_cliente');
                context.commit("setGrupoCliente", data);
            }
            
            if(!store.has('grupo_cliente') || vr_){
                let data = (await context.getters.authenticatedAxios.get(grupoClienteURL)).data
                store.set('grupo_cliente',data.data.grupos)
                context.commit("setGrupoCliente", data.data.grupos);
            }
        },
        async getContasBancarias01(context,vr_ = false) {
            if(store.has('contas_bancarias_01')) {
                let data = store.get('contas_bancarias_01');
                context.commit("setContasBancarias01", data);
            }
            
            if(!store.has('contas_bancarias_01') || vr_){
                let data = (await context.getters.authenticatedAxios.get(contasBancarias01URL)).data
                store.set('contas_bancarias_01',data.data.contas)
                context.commit("setContasBancarias01", data.data.contas);
            }
        },
        async getSoftware(context,vr_ = false) {
            if(store.has('software')) {
                let data = store.get('software');
                context.commit("setSoftware", data);
            }
            
            if(!store.has('software') || vr_){
                let data = (await context.getters.authenticatedAxios.get(listSoftwareURL)).data
                store.set('software',data.data.softwares)
                context.commit("setSoftware", data.data.softwares);
            }
        },
        async getTipologias(context,condo_id) {
                let data = (await context.getters.authenticatedAxios.get(tipologias+"?id_condominio="+condo_id)).data
                context.commit("setTipologias", data);
        },
        clientAtive(context,data) {
            context.commit("setClientAtive", data);
        },
        setEmailBoxSelect(context,data) {
            context.commit("setEmailBoxSelect_", data);
        }
    }
}

