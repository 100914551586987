import Vue from 'vue'
import Axios from "axios";
import Vuex from 'vuex'
import AuthModule from "./auth";
import C7Module from "./c7ly";
import TbApoio from "./tbapoio";
import StoreCloud from "./cloud";
import StoreMail from "./email";
import persistedState from "vuex-persistedstate";

const baseUrl = "http://localhost:7500";
const loginUrl = baseUrl+"/account/login";

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: { auth: AuthModule, c7layout: C7Module, tba: TbApoio,cloud: StoreCloud,email:StoreMail},
  plugins: [persistedState()]
})
