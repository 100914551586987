const baseUrl = process.env.VUE_APP_BASE_URL;

const labelsMBUrl = baseUrl+"/mailbox/get/labeles";
import store from 'storejs';
export default {
    state: {
        act_label_selected:"",
        labels:[]
    },
    getters: {
        labelActive(state) {
            return state.act_label_selected;
        }
    },
    mutations: {
        setMbLabels(state, data) {
            state.labels = data
        },
        setActiveLabel(state, data) {
            state.act_label_selected = data
        },
    },
    actions: {
        MbactiveLabel(context, val) {
            context.commit("setActiveLabel",val);
        },
        async getMailBoxLabels(context,vr_ = false) {
            if(store.has('mail_box_labels')) {
                let data = store.get('mail_box_labels');
                context.commit("setMbLabels", data);
            }
            
            if(!store.has('mail_box_labels') || vr_){
                let data = (await context.getters.authenticatedAxios.get(labelsMBUrl)).data
                store.set('mail_box_labels',data.data)
                context.commit("setMbLabels", data.data);
            }
        }
    }
}

