<template>
      <div class="nk-split nk-split-page nk-split-md">
          <div class="nk-split-content nk-block-area nk-block-area-column nk-auth-container">
              <div class="absolute-top-right d-lg-none p-3 p-sm-5">
                  <a href="#" class="toggle btn-white btn btn-icon btn-light" data-target="athPromo"><em
                          class="icon ni ni-info"></em></a>
              </div>
               <router-view/>
               <!-- .nk-block -->
              <div class="nk-block nk-auth-footer">
                  <div class="nk-block-between">
                      <ul class="nav nav-sm">
                          <li class="nav-item">
                              <a class="nav-link" href="mailto:activate@ees.ao">Help Center</a>
                          </li>
                      </ul><!-- .nav -->
                  </div>
                  <div class="mt-3">
                      <p>&copy; 2019 Exter Experts. Todos os direitos reservados.</p>
                  </div>
              </div><!-- .nk-block -->
          </div><!-- .nk-split-content -->
          <div class="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
              data-content="athPromo" data-toggle-screen="lg" data-toggle-overlay="true">
              <div class="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
                <VueSlickCarousel class="slider-init" :speed="1100" :arrows="false" :dots="true" :autoplay="true">
                    <div class="slider-item">
                        <div class="nk-feature nk-feature-center">
                            <div class="nk-feature-img">
                                <img class="round" src="/active/61f6ffd39ab02.jpg"
                                    srcset="/active/61f6ffd39ab02.jpg 2x" alt="">
                            </div>
                            <div class="nk-feature-content py-4 p-sm-5">
                                <h4>Um Novo Paradigma</h4>
                                <p>Com a Exter nasce um novo paradigma, somos mais inovação e criatividade tudo isso acompanhado com o mais elevado grau de excelência.</p>
                            </div>
                        </div>
                    </div><!-- .slider-item -->
                    <div class="slider-item">
                        <div class="nk-feature nk-feature-center">
                            <div class="nk-feature-img">
                                <img class="round" src="/active/1216968892.jpg"
                                    srcset="/active/1216968892.jpg 2x" alt="">
                            </div>
                            <div class="nk-feature-content py-4 p-sm-5">
                                <h4>Ficamos Mais Próximo</h4>
                                <p>Apostamos em inovação e rigor na prestação de serviços, encurtamos tempo e espaço para melhor lhe servir focando se assim no melhor que soubemos fazer "Dar vida as tuas ideias ".</p>
                            </div>
                        </div>
                    </div><!-- .slider-item -->
                </VueSlickCarousel>
              </div><!-- .slider-wrap -->
          </div><!-- .nk-split-content -->
      </div><!-- .nk-split -->
  </template>
  
  <script>
  import VueSlickCarousel from 'vue-slick-carousel'
  
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  import LangMenu from '@/components/auth/langMenu.vue'
  export default {
    name: 'Home',
    components: {
      VueSlickCarousel,
      LangMenu
    },
    methods: {
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;

            if(addRemoveClass === 'addClass') {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        }
    },
    mounted() {
        this.toggleBodyClass('addClass',"pg-auth")
        "nk-body bg-white npc-default pg-auth no-touch nk-nio-theme"
        "nk-body npc-crypto ui-clean pg-auth"
    }
  }
  </script>
  