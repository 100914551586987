export default {
    state: {
        darkActive: false,
        overActive: false,
        modalNovaFracao: false,
        tabselect_d:1,
        condominioCofig: {}
    },
    getters: {
        isActiveDark(state) {
            return state.darkActive;
        }
    },
    mutations: {
        setCondoCog(state, config) {
            state.condominioCofig = config;
        },
        showNovafracao(state,obj) {
            state.modalNovaFracao = true;
        },
        hideNovafracao(state) {
            state.modalNovaFracao = false;
        },
        setActiveDark(state) {
            state.darkActive = true;
        },
        setSelectTab(state, val) {
            state.tabselect_d = val;
        },
        resetActiveDark(state) {
            state.darkActive = false;
        },
        setActiveOver(state) {
            state.overActive = true;
        },
        resetActiveOver(state) {
            state.overActive = false;
        }
    },
    actions: {
        modalNovaFracaoShow(context, value,obj) {
            if(value == true)
                context.commit("showNovafracao",obj);
            else
                context.commit("hideNovafracao");
        },
        darkmodeLayout(context, value) {
            if(value == true)
                context.commit("setActiveDark");
            else
                context.commit("resetActiveDark");
        },
        ovelayLaoyut(context, value) {
            if(value == true)
                context.commit("setActiveOver");
            else
                context.commit("resetActiveOver");
        },
        ActiveConfig(context, value) {
                context.commit("setCondoCog",value);
        }
    }
}

