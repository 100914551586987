import Vue from 'vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueRouter from 'vue-router'
import Activate from '../views/admin/activatApp/Activate'
import LayoutACTIVATE from '../views/admin/activatApp/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LayoutACTIVATE',
    component: LayoutACTIVATE,
    children:[
      { 
        alias:'',
        path:'/activate',
        component:Activate,
        name: 'new-active'
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeResolve((to, from, next) => {
  if(to.name){
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
