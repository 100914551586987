import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { Affix, Page, TimePicker,Collapse,Panel,Tabs,TabPane, RadioGroup,Radio, Button, Poptip, Input, Table, Select,Option,Dropdown,DropdownMenu,DropdownItem,Modal,Steps,Step,DatePicker,Locale,Notice,Message,Alert,Upload,Spin,InputNumber,Avatar,AutoComplete,Drawer } from 'view-design';
import VueNumeric from 'vue-numeric'
import lang from 'view-design/dist/locale/pt-PT';
import VueCurrencyFilter from 'vue-currency-filter'
Locale(lang);

import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);

import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code',VueQRCodeComponent);


Vue.component('Drawer', Drawer);
Vue.component('Button', Button);
Vue.component('Collapse', Collapse);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
Vue.component('Panel', Panel);
Vue.component('VueNumeric', VueNumeric);
Vue.component('Table', Table);
Vue.component('Select', Select);
Vue.component('AutoComplete', AutoComplete);
Vue.component('Option', Option);
Vue.component('Dropdown', Dropdown);
Vue.component('a-vatar', Avatar);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Modal', Modal);
Vue.component('Steps', Steps);
Vue.component('Step', Step);
Vue.component('i-upload', Upload);
Vue.component('DatePicker', DatePicker);
Vue.component('Alert', Alert);
Vue.component('Poptip', Poptip);
Vue.component('Input', Input);
Vue.component('i-number', InputNumber);
Vue.component('RadioGroup', RadioGroup);
//Vue.component('TimePicker', TimePicker);
Vue.component('Radio', Radio);
Vue.component('Page', Page);
Vue.component('Affix', Affix);
Vue.component('Spin', Spin);

Vue.prototype.$Notice = Notice;
Vue.prototype.$Message = Message;
Vue.prototype.$Modal= Modal;

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import VueSimplebar from 'vue-simplebar'
Vue.use(PerfectScrollbar)
Vue.use(Vuelidate)
Vue.use(VueSimplebar)
Vue.use(VueCurrencyFilter,[
    {
        symbol : 'kz',
        thousandsSeparator: '.',
        fractionCount: 2,
        fractionSeparator: ',',
        symbolPosition: 'back',
        symbolSpacing: true,
        avoidEmptyDecimals: undefined,
    },
    {
        name:'numerial',
        symbol : '',
        thousandsSeparator: '.',
        fractionCount: 2,
        fractionSeparator: ',',
        symbolPosition: 'back',
        symbolSpacing: true,
        avoidEmptyDecimals: undefined,
    }
])

import 'bootstrap-vue/dist/bootstrap-vue.css'
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
