export default {
    state: {
        parente_store_:"",
        cloud_store_name_:"",
        cloud_view_style:"nk-files-view-grid",
    },
    getters: {
        parentStore(state) {
            return state.parente_store_;
        }
    },
    mutations: {
        setParentStore(state, data) {
            state.parente_store_ = data.id;
            state.cloud_store_name_ = data.nome;
        },
        setParentStoreIndex(state, data) {
            state.parente_store_ = "";
            state.cloud_store_name_ = "";
        },
        setNkView(state,class_) {
            state.cloud_view_style = class_;
        }
    },
    actions: {
        folderStore(context, val) {
            context.commit("setParentStore",val);
        },
        folderStoreIndex(context, val) {
            context.commit("setParentStoreIndex");
        },
        setNKViewStyle(context, val) {
            context.commit("setNkView",val);
        }
    }
}

