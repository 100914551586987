import Axios from "axios";
import store from 'storejs';
import Echo from 'laravel-echo'

const UNAUTHORIZED = 401;


Axios.interceptors.response.use(response => response, error => {
    const {status} = error.response;
    console.log(response);
    return Promise.reject(error)
})

Axios.interceptors.request.use((config) => {
    config.headers['X-Socket-ID'] = window.socketId

    return config
})

const baseUrl = process.env.VUE_APP_BASE_URL;
const loginUrl = baseUrl+"/account/authenticate";
const logoutUrl = baseUrl+"/logout";
const condominiosAdministrados = baseUrl+"/condominios/administrados";
export default {
    state: {
        authenticated: false,
        account: [],
        administrados: [],
        condSelecionado: 3,
        jwt: null,
        socketID: null,
        authError: null
    },
    getters: {
        authenticatedAxios(state) {
            return Axios.create({
                headers: {
                    "Authorization": `Bearer ${state.jwt}`,
                    "X-Socket-ID": `${state.socketID}`
                }
            });
        },
        socketEcho(state) {
            return new Echo({
                broadcaster: 'socket.io',
                host: 'http://62.171.139.225:6001'
            })
        },
        socketEchoAuth(state) {
            return new Echo({
                broadcaster: 'socket.io',
                host: 'http://62.171.139.225:6001',
                auth: {
                    headers: {
                        "Authorization": `Bearer ${state.jwt}`
                    }
                }
            })
        },
        authenticatedAxiosCms(state) {
            return Axios.create({
                headers: {
                    "Authorization": `Basic ${btoa("superadmin@mail.com:vbzt9Q-mZT7K-G8fTmm-pKezp")}`
                }
            });
        },
        jsonAuthenticatedAxios(state) {
            return Axios.create({
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.jwt}`
                }
            });
        },

    },
    mutations: {
        setAuthenticated(state, header) {
            state.jwt = header._token;
            sessionStorage.token = header._token;
            //state.account = header.data;
            state.authenticated = true;
        },
        setCondsAdministrados(state, data) {
            state.administrados = data;
        },
        setCondSelecionadao(state, data) {
            state.condSelecionado = data;
        },
        setAuthM(state, data) {
            state.account = data;
            store.set('account',data);
        },
        setAuthError(state, error) {
            state.authError = error;
        },
        setLogout(state, data) {
            state.account = [];
            state.authenticated = false;
            store.set('account',[]);
        },
        clearAuthentication(state) {
            state.authenticated = false;
            state.jwt = null;
        },
        setSocketID(state,val) {
            state.socketID = val;
        }
    },
    actions: {
        async authenticate(context, credentials) {
            let formData = new FormData();
            formData.append('email', credentials.Username)
            formData.append('password', credentials.Password)

            let response = await Axios.post(loginUrl, formData).then(res => {
                if (res.status== 200) {


                    context.commit("setAuthenticated", res.data.data);
                } else if(res.status== 401) {
                    console.log("Não autorizado");
                } else {
                    console.log(res)
                    context.commit("setAuthError", res.data.nsg);
                }
            }).catch(error => {
                console.log(error,'erro aqui')
                context.commit("setAuthError", 'Erro! Não Autorizado pela Exter.');
            })
        },
        async getCondominiosAdministrados(context) {
            let data = (await context.getters.authenticatedAxios.get(condominiosAdministrados)).data;
            context.commit("setCondsAdministrados", data);
        },
        async userLogout(context) {
            let data = (await context.getters.authenticatedAxios.post(logoutUrl)).data;
            context.commit("setLogout", data);
        },
        selecionarCondominio(context, condominio_id) {
            context.commit("setCondSelecionadao", condominio_id)
            context.dispatch("getTipologias",condominio_id)
        },
        setIDSocket(context,val) {
            context.commit("setSocketID",val);
        },
        setAuthLogin(context, data) {
            context.commit("setAuthM", data.User)
            //context.dispatch("getTipologias",condominio_id)
        }
    }
}
