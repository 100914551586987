<template>
    <div class="nk-block nk-block-middle nk-auth-body">
        <div class="brand-logo pb-5">
            <a href="html/general/index.html" class="logo-link">
                <img class="logo-light logo-img logo-img-lg" src="/active/ilha.png"
                    srcset="/active/ilha.png 2x" alt="logo">
                <img class="logo-dark logo-img logo-img-lg" src="/active/ilha.png"
                    srcset="/active/ilha.png 2x" alt="logo-dark">
            </a>
        </div>
        <div class="nk-block-head">
            <div class="nk-block-head-content">
                <h5 class="nk-block-title">Active 360</h5>
                <div class="nk-block-des">
                    <p>Utilize o formulário abaixo para submeter os ficheiros de licença exter.</p>
                </div>
            </div>
        </div><!-- .nk-block-head -->
        
        <div class="c360-msg">
            <Alert type="error" show-icon v-show="showFailureMessage">
                 Erro!
                 <span slot="desc">
                     {{ errmessage }}
                 </span>
            </Alert>
        </div>
        <form class="c-get-login" v-on:submit.prevent="validar_loop">
            <div class="form-group">
                        <i-upload
                    
                    type="drag"
                    :action="url_file_upload"
                    :data="uploadParams"
                    :format="['lic']"
                    :max-size="1048"
                    :before-upload="handleBeforeUpload"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize"
                    :on-success="list_env"
                    :on-error="handleError"
                    >
                    <div class="support-upload-box bg-lighter" style="padding: 60px 10px">
                                <div class="upload-zone">
                                    <div class="dz-message" data-dz-message>
                                        <span class="dz-message-text"><span style="display: inline-block;color: #6576ff;">Arraste e solte</span> o arquivo aqui ou <span style="display: inline-block;color: #6576ff;">clique aqui para escolher</span></span>
                                    </div>
                                </div>
                            </div>
                    </i-upload>
                        <validation-error v-bind:validation="$v.password" />
            </div><!-- .foem-group -->
            <div class="form-group">
                <button class="btn btn-lg btn-primary btn-block here" v-show="show_validator" :disabled="loading">
                  <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span v-show="loading">A guarde...</span>
                  <span v-show="!loading">Validar Licença</span>
                </button>

                <div v-show="show_code" class="alert alert-success alert-icon mt-3">
                    <em class="icon ni ni-check-circle"></em> Utilize o código abaixo para ativar uma nova licença exter.<br>
                    <span style="font-size: 30px;">{{ code }}</span>
                </div>
            </div>
        </form><!-- form -->
        <div class="form-note-s2 pt-4"> Não possui um ficheiro de licença válido? <a
                href="tel:+244928003524">Faleconosco</a>
        </div>
        
    </div>
</template>
<script>
    const baseUrl = process.env.VUE_APP_BASE_URL_PRO;
    import VueRouter from 'vue-router';
    const { isNavigationFailure, NavigationFailureType } = VueRouter;

    import { required } from "vuelidate/lib/validators";
    import { mapActions, mapState,mapGetters } from "vuex";
    import ValidationError from "../../../components/ValidationError";
    export default {
        name: 'Login',
        components: { ValidationError },
        data() {
            return {
                showPass: false,
                desableForm: false,
                show_validator: false,
                show_code: false,
                loading: false,
                code:"",
                username: "",
                password: "",
                showFailureMessage: false,
                errmessage: '',
                
                uploadParams: {},
                url_file_upload:"",
                token_temp_file:"",
            }
        },
        computed: {
            ...mapState({ authenticated: state => state.auth.authenticated }),
            ...mapGetters(["authenticatedAxios","socketEchoAuth"]),
        },
        validations: {
            username: { required },
            password: { required }
        },
        methods: {
            ...mapActions(["authenticate","getCondominiosAdministrados","setAuthLogin","setIDSocket"]),
            handleBeforeUpload(request) {
                this.uploadParams.token = this.jwt;
                this.uploadParams.key = request.name;
            },
            validar_loop() {
                this.loading = true;
                setTimeout(() => {
                    this.show_validator = false;
                    this.loading = false;
                    this.show_code = true;
                }, 2200);
            },
            handleFormatError (file) {
                this.$Notice.warning({
                    title: 'O formato do arquivo está incorreto',
                    desc: 'O formato de arquivo de ' + file.name + ' está incorreto selecione .lic.'
                });
            },
            handleError (file) {
                this.$Notice.warning({
                    title: 'Ficheiro invalido',
                    desc: 'O arquivo de licença não foi processado, é um ficheiro invalido, queira gerar um novo ficheiro e submeter.'
                });
            },
            handleMaxSize (file) {
                this.$Notice.warning({
                    title: 'Limite de tamanho de arquivo excedido',
                    desc: 'O arquivo ' + file.name + ' é muito grande, excedeu os 2M.'
                });
            },
            list_env(val,val1,val2) {
                setTimeout(() => {
                    this.show_validator = true;
                }, 1200);
                this.code = val;
            },
            toggleBodyClass(addRemoveClass, className) {
                const el = document.body;

                if(addRemoveClass === 'addClass') {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },
        },
        mounted() {
            //this.toggleBodyClass('addClass',"pg-auth");
            const el = document.body;
            el.className = 'nk-body bg-white npc-default pg-auth'

            this.url_file_upload = baseUrl + "/activate/app";

            console.log(this.url_file_upload);
        }
    }
</script>
<i18n>
{
  "en": {
    "hello": "Hello i18n in SFC!"
  }
}
</i18n>